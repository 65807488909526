import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Contact } from '../../models/contact.model'

export interface ContactsState {
  contacts: Contact[]
  loading: boolean
  error: string | null
}

const initialState: ContactsState = {
  contacts: [],
  loading: false,
  error: null,
}
const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    addContact: (state, action: PayloadAction<Contact>) => {
      state.contacts.push(action.payload)
    },
    editContact: (state, action: PayloadAction<Contact>) => {
      const index = state.contacts.findIndex(
        (contact) => contact.id === action.payload.id
      )
      state.contacts[index] = action.payload
    },
    removeContact: (state, action: PayloadAction<number>) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== action.payload
      )
    },
  },
})

export const contactsReducer = contactsSlice.reducer

export const { addContact, editContact, removeContact } = contactsSlice.actions
